<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Data Siswa">
      <!-- Title -->
      <div class="d-flex pb-2">
        <b-avatar
          :src="blobImage"
          :text="avatarText('Siswa')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              accept="image/png, image/jpg, image/jpeg"
              ref="file"
              type="file"
              style="display: none"
              @change="onFileChange"
            />
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <b-button @click="blobImage = ''" variant="secondary">
              Reset
            </b-button>
          </div>
          <span>Upload foto kelas format jpg/png maks. 800kb</span>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Data Pribadi</h6>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Lengkap</label>
            <b-form-input
              v-model="formData.fullname"
              placeholder="Masukkan Nama Lengkap"
            />
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Email</label>
            <b-form-input
              v-model="formData.emailAddress"
              placeholder="Masukkan Email"
            />
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Password</label>
            <b-form-input
              v-model="formData.fullname"
              placeholder="Masukkan Password"
              :state="formErrors.fullname.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.fullname.state">
              {{ formErrors.fullname.message }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Konfirmasi Password</label>
            <b-form-input
              v-model="formData.emailAddress"
              placeholder="Masukkan Ulang Password"
              :state="formErrors.emailAddress.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.emailAddress.state">
              {{ formErrors.emailAddress.message }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col lg="6">
            <label class="d-block">Agama</label>
            <b-form-group>
              <v-select
                v-model="formData.religion"
                label="title"
                :options="religion"
              />
            </b-form-group>
          </b-col>

          <b-col lg="6" class="mb-1">
            <label class="d-block">Nomor Telepon</label>
            <b-form-input placeholder="Nomor Telepon" />
          </b-col>
          <b-col lg="6">
            <label class="d-block">Tempat Lahir</label>
            <b-form-input
              class="form-control"
              v-model="formData.birthplace"
              placeholder="Tempat Lahir"
            />
          </b-col>
          <b-col lg="6">
            <label class="d-block">Tanggal Lahir</label>
            <b-form-group>
              <flat-pickr
                class="form-control"
                v-model="formData.classroomStartDate"
                placeholder="Tanggal Lahir"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y',
                  dateFormat: 'Y-m-d',
                }"
              />
            </b-form-group>
          </b-col>

          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Orang Tua</label>
            <b-form-input
              v-model="formData.fullname"
              placeholder="Masukkan Nama Orang Tua"
            />
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Wali</label>
            <b-form-input
              v-model="formData.fullname"
              placeholder="Masukkan Nama Wali"
            />
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Alamat</label>
            <b-form-textarea
              placeholder="Alamat"
              rows="3"
              no-resize
              v-model="formData.address"
              :state="formErrors.address.state"
            />

            <b-form-invalid-feedback v-show="!formErrors.address.state">
              {{ formErrors.address.message }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'

// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      blobImage: '',
      formErrors: {
        fullname: { state: null, message: '' },
        emailAddress: { state: null, message: '' },
        address: { state: null, message: '' },
        classroomSchedule: { state: null, message: '' },
        classroomStartDate: { state: null, message: '' },
      },

      religion: ['Islam', 'Kristen', 'Katolik', 'Hindu', 'Budha', 'Konghucu'],

      formData: {
        fullname: '',
        emailAddress: '',
        birthplace: '',
        religion: 'Islam',
        address: '',
        classroomImage: '',
        classroomCover: '',
        schoolId: '',
      },
    }
  },
  mounted() {},
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.classroomLogo = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.classroomName === '') {
        this.formErrors.classroomName = {
          state: false,
          message: 'Nama Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomName = {
          state: null,
          message: '',
        }
      }

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`

        const data = new FormData()
        data.append('name', this.formData.classroomName)
        data.append('description', this.formData.classroomDescription)
        data.append('logo', this.formData.selectedIcon)
        data.append('image', this.formData.classroomLogo)
        data.append('cover', this.formData.classroomLogo)
        data.append('schedule', this.formData.classroomSchedule)
        data.append('enrollment_key', this.formData.classroomEnrollKey)
        data.append('school_id', this.formData.schoolId)
        data.append('start_date', this.formData.classroomStartDate)

        const config = {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }

        // create classroom
        await axios
          .post(`${baseUrl}/classrooms`, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Kelas berhasil dibuat'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('classroom-detail'),
              params: { id: response.data.data.id },
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
